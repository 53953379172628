import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class HomeService{
    baseUrl=environment.apiUrl;

    constructor(private http:HttpClient){}

    public  getDashboardStats(){
        return this.http.get(this.baseUrl + "ChangeRequest/dashboardStats");
    }
}